import React, { useState } from "react";
import { observer, inject } from "mobx-react"

import MenuItem from "@mui/material/MenuItem";
import { MapissoMenu } from "./MapissoMenu";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const MapissoSubMenu = inject("store")(observer((props) => {

    const [menuOpen, setMenuOpen] = useState(false)
    const [anchorElement, setAnchorElement] = useState(null)

    const handleItemClick = (event) => {
        if (!anchorElement) {
            setAnchorElement(event.currentTarget);
        }

        setMenuOpen(!menuOpen)
    }

    const handleSubMenuClose = () => {
        setMenuOpen(false);
    }

    return (
        <>
            <MenuItem onClick={handleItemClick}>
                {props.caption}
                <ArrowRightIcon style={{fontSize: "20px", color: "gray", marginTop: "5px"}}/>
            </MenuItem>
            <MapissoMenu open={menuOpen} menuItems={props.menuItems}
                anchorEl={anchorElement} onClose={handleSubMenuClose} />
        </>
    )

}))