import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { Card, CardContent, Container} from '@mui/material';
import { ApiKeyAPI } from '../apis/ApiKeyAPI';

export const AddApiKeyPage = inject("store")(observer((props) => {

    const navigate = useNavigate();
    const [name, setName] = useState("")

    const breadCrumbs = [
        { title: "REAZY.CO", url: "/" },
        { title: "API Anahtarları", url: "/apikeys" },
        { title: "API Anahtarı Oluştur", url: "/new-api-key" }
    ]

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/login")
        }
        // eslint-disable-next-line
    }, []);

    const onNameTextFieldChange = (event) => {
        setName(event.target.value)
    }

    const onAddApiKey = async () => {
        props.store.setLoadingBarStatus(false)

        if (name !== "") {

            let apiToken = {
                name: name
            }

            let response = await ApiKeyAPI.create(props, apiToken, false)
            
            if (response.status === 200) {
                props.store.setMessage("Apikey created successfully.", true)
            } else {
                props.store.setMessage("While creating apikey, error occurs. Please try again.", true)
            }
        } else {
            props.store.setMessage("Api keyname cannot be empty.", true)
        }

        props.store.setLoadingBarStatus(true)

    }

    return (
        <div className="container-scroller">
            <ResponsiveAppBar page="API ANAHTARLARI" breadCrumbs={breadCrumbs} breadCrumbsDisplay={true} />

            <Container maxWidth="xl">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{ marginTop: "0px", fontWeight: "bold" }}>API Anahtarı Oluştur</h2>
                        <p>Reazy.co risk ve tile servislerine erişebilmek için bir API anahtarı oluşturmanız gerekmektedir.</p>
                    </div>
                </div>
                <Card>
                    <CardContent>
                        <div className="form-group">
                            <p style={{marginTop: "20px"}}><b>API Anahtarı İsmi:</b></p>
                            <TextField id="3dtiles-name"
                                variant="outlined"
                                style={{ width: "100%", marginBottom: "20px"}} onChange={onNameTextFieldChange} />
                        </div>
                        <div className="form-group">
                            <Button variant="contained" color="secondary" onClick={onAddApiKey}
                                style={{ height: "50px", fontWeight: "600", float: "right", marginBottom: "50px" }}>
                                <AddCircleOutlineIcon style={{ marginRight: "10px" }} /> Create api key
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}))

export default inject("store")(observer(AddApiKeyPage));