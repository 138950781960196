import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";

import ServicesPage from './pages/ServicesPage';
import StatisticsPage from './pages/StatisticsPage';
import BillingPage from './pages/BillingPage';
import LoginPage from './pages/LoginPage';
import ApiKeysPage from './pages/ApiKeysPage'
import AddApikeyPage from './pages/AddApikeyPage';
import AccountPage from './pages/AccountPage';

import darkTheme from './themes/dark-theme';
import lightTheme from './themes/light-theme';

class App extends Component {

  render() {
    return (
      <ThemeProvider theme={(this.props.store.darkMode) ? darkTheme : lightTheme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route exact path="/" element={<ServicesPage/>} />
            <Route exact path="/login" element={<LoginPage/>} />
            <Route exact path="/dashboard" element={<ServicesPage/>} />
            <Route exact path="/apikeys" element={<ApiKeysPage/>} />
            <Route exact path="/new-api-key" element={<AddApikeyPage/>} />
            <Route exact path="/statistics" element={<StatisticsPage/>} />
            <Route exact path="/billings" element={<BillingPage/>} />
            <Route exact path="/account" element={<AccountPage/>} />
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }
}

export default inject("store")(observer(App));

