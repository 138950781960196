import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import { Container } from '@mui/material';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { BillAPI } from '../apis/BillAPI';

export const BillingPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const [billList, setBillList] = useState([])

    const breadCrumbs = [
        { title: "REAZY.CO", url: "/" },
        { title: "Faturalar", url: "/billings" }
    ]

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/login")
        } else {
            getBillList()
        }
        // eslint-disable-next-line
    }, []);

    const getBillList = async () => {
        props.store.setLoadingBarStatus(false)

        let billListGetResponse = await BillAPI.getList(props, false)

        if (billListGetResponse.status === 200) {
            let billListData = billListGetResponse.result

            if (billListData.length > 0) {
                setBillList(billListData)
            } else {
                setBillList([])
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const findMonthName = (monthNumber) => {
        var months = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
        return months[monthNumber];
    }

    return (
        <>
            <ResponsiveAppBar page="FATURA" breadCrumbs={breadCrumbs} breadCrumbsDisplay={true} />
            <Container maxWidth="xl">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{ marginTop: "0px", fontWeight: "bold" }}>Fatura</h2>
                        <p>Sınırlar olmadan Reazy.co servislerine erişim için enterprise plana geçiş yapın</p>
                    </div>
                </div>
                <div className="row">
                    <Paper elevation={0} style={{ padding: "25px" }}>

                        <h4>Mevcut planınız</h4>
                        <div className="row billing-plan-container">
                            <div className="col-9">
                                <AccountBalanceWalletIcon style={{ color: '#4caf50', fontSize: '41px' }} />
                                <p style={{ marginTop: "9px", fontWeight: "bold" }}>Enterprise</p>
                            </div>
                            <div className="col-3" style={{ textAlign: "right" }}>
                                Tebrikler. Ayrıcalıklı kullanıcılarımız arasındasınız.
                            </div>
                        </div>
                    </Paper>
                </div>

                <div className="row">
                    <TableContainer component={Paper} style={{ marginTop: "50px" }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Sıra No</b></TableCell>
                                    <TableCell ><b>Fatura Yıl</b></TableCell>
                                    <TableCell align="left"><b>Fatura Ay</b></TableCell>
                                    <TableCell align="left"><b>Risk Servisi Kullanımı</b></TableCell>
                                    <TableCell align="left"><b>Tile Servisi Kullanımı</b></TableCell>
                                    <TableCell align="left"><b>Bedel</b></TableCell>
                                    <TableCell align="left"><b>Tarih</b></TableCell>
                                    <TableCell align="left"><b>Ödeme Durumu</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billList.map((item, idx) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="left">{idx + 1}</TableCell>
                                        <TableCell component="th" scope="row">{item.year}</TableCell>
                                        <TableCell align="left">{findMonthName(item.month)}</TableCell>
                                        <TableCell align="left">{item.risk}</TableCell>
                                        <TableCell align="left">{item.tile}</TableCell>
                                        <TableCell align="left">{item.price} TRY</TableCell>
                                        <TableCell align="left">{item.date}</TableCell>
                                        <TableCell align="left">{(item.status) ? "Ödendi" : "Ödenmedi"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Container>
        </ >
    )
}))

export default inject("store")(observer(BillingPage));