import { api } from "./configs/AxiosConfigs"
import { defineCancelApiObject } from "./configs/AxiosUtils"

import config from '../config/client.json'

export const UserAPI = {
    logOut: async function (props, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `${config.serviceUrl}/user/logout/${props.store.token}`,
            method: "PUT",
            data: null,
            signal: cancel ? cancelApiObject[this.logOut.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    },
    login: async function (props, email, password) {
        props.store.setLoadingBarStatus(false)
        const basicAuth = btoa(email + ":" + password)
 
        try {
            const response = await api.request({
                url: `${config.serviceUrl}/user`,
                method: "PUT",
                headers: { 'Authorization': "Basic " + basicAuth, 'Content-Type': "application/json" },
                data: null
            })

            props.store.setLoadingBarStatus(true)
            return response.data
        } catch (error) {
            console.log("errpr: ", error)
            if (error.response) {
                // The request was made, but the server responded with a 401 status code
                console.error('Error Response Data:', error.response.data);
                console.error('HTTP Status Code:', error.response.status);
            } else if (error.request) {
                // The request was made, but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something else happened
                console.error('Error:', error.message);
            }
            return null
        }
    }
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UserAPI) 