import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import KeyIcon from '@mui/icons-material/Key';
import MoneyIcon from '@mui/icons-material/Money';

export const pageList =
    [
        {
            key: "1",
            name: "ANA SAYFA",
            url: "/dashboard",
            icon: <DashboardIcon />,
            isAdmin: false,
        },
        {
            key: "2",
            name: "API ANAHTARLARI",
            url: "/apikeys",
            icon: <KeyIcon />,
            isAdmin: false,
        },
        {
            key: "2",
            name: "İSTATİSTİK",
            url: "/statistics",
            icon: <FormatListNumberedIcon />,
            isAdmin: false
        },
        {
            key: "3",
            name: "FATURA",
            url: "/billings",
            icon: <MoneyIcon />,
            isAdmin: false
        },
    ]