import React, { useState, useEffect } from 'react'
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import MapissoAlert from '../components/MapissoAlert'

import config from '../config/client.json'
import { UserAPI } from '../apis/UserAPI';

const LoginPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const [loadingBarStatus, setLoadingBarStatus] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        setLoadingBarStatus(true)
        if (!props.store.isLogin) {
            navigate("/login")
        } else {
            navigate("/dashboard")
        }
        // eslint-disable-next-line
    }, [])

    const onLogin = async () => {
        setLoadingBarStatus(false)
        let response = await UserAPI.login(props, email, password);
        if (response.status === 200) {
            
            let user = response.result

            props.store.setUser(user)
            props.store.setToken(user.token)
            props.store.setUserLogged()

            setLoadingBarStatus(true)
            navigate("/dashboard")

        } else {
            props.store.setToken("")
            props.store.setMessage(response.data.message, true, "error")
            setLoadingBarStatus(true)
        }
    }

    const onEmailTextFieldChange = (event) => {
        setEmail(event.target.value)
    }

    const onPasswordTextFieldChange = (event) => {
        setPassword(event.target.value)
    }

    const onKeyPressHandler = (target) => {
        if (target.charCode === 13) {
            onLogin()
        }
    }

    return (
            <div className="container mps-container">
                <div className="row width100vh" style={{ margin: "0" }}>
                    <div className="col-md-4 height100vh" style={{ padding: "75px", maxWidth: "450px", minWidth: "450px", paddingTop: "20px" }}>
                       
                        <Typography variant="h4" style={{ textAlign: "left", fontWeight: "bold" }}>{config.appName} Panel</Typography>
                        <Typography variant='body1' style={{marginBottom: "50px"}}>
                            Yüksek doğruluklu
                            risk haritalarına online erişmek artık çok kolay.
                        </Typography>
                        <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", marginTop: "50%" }}>
                        Reazy.co'ya hoşgeldiniz
                        </Typography>

                        <p style={{ fontWeight: "bold", marginTop: "10px" }}>E-posta</p>
                        <TextField value={email} variant="filled" style={{ width: "100%" }}
                            onChange={onEmailTextFieldChange} onKeyPress={onKeyPressHandler} />
                        <p style={{ fontWeight: "bold", marginTop: "25px" }}>Şifre</p>
                        <TextField value={password} variant="filled" type="password"
                            onKeyPress={onKeyPressHandler} style={{ width: "100%", marginBottom: "10px" }}
                            onChange={onPasswordTextFieldChange} />
                        <p></p>
                        <Button variant="contained" color="primary" onClick={onLogin}
                            style={{ width: "100%", height: "50px", fontWeight: "600" }}>
                            GİRİŞ YAP
                        </Button>
                        <LinearProgress hidden={loadingBarStatus} color="secondary" />
                    </div>
                    <div className='col-md-8' style={{ padding: "20px", maxWidth: "calc(100% - 450px)", minWidth: "calc(100% - 450px)" }}>
                        <div className={(props.store.darkMode) ? "bg-image-darkmode" : "bg-image"}
                            style={{ width: "100%", paddingTop: "10px" }}>
                            <Typography variant="body1" style={{ marginLeft: "30px", marginTop: "10px", color: "#fff" }}>{config.appName}</Typography>
                            <Typography variant="body1" style={{ marginLeft: "30px", color: "#fff" }}>{config.version}</Typography>
                            <Typography variant="caption" style={{ marginLeft: "30px", color: "#fff" }}>© 2012-2024. Tüm Hakları Saklıdır</Typography>

                        </div>
                    </div>
                </div>
                <MapissoAlert />
            </div>
    )
}))

export default inject("store")(observer(LoginPage));