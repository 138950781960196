import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'

import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import PersonIcon from '@mui/icons-material/Person'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import MapissoAlert from './MapissoAlert';
import { MapissoMenu } from './MapissoMenu'

import { pageList } from '../pages/PagesDefArray';
import config from '../config/client.json'

export const ResponsiveAppBar = inject("store")(observer((props) => {


    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [menuTarget, setMenuTarget] = useState(null)
    const [selectedMenuItems, setSelectedMenuItems] = useState([])
    const [menuDisplay, setMenuDisplay] = useState(false)

    const pages = pageList;

    useEffect(() => {
        getBildirimListesi()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBildirimListesi = async () => {
        props.store.setLoadingBarStatus(false)

        props.store.setLoadingBarStatus(true)
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const onClickDarkLightModeButton = () => {
        props.store.setDarkMode()
    }

    const onClickLogout = async () => {
        //let token = props.store.user[0].token
        try {
            props.store.setUserLogout()
            navigate("/login")
        } catch (error) {
            navigate("/login")
        }
    }

    const onClickMenuClose = () => {
        setMenuDisplay(false)
        setMenuTarget() 
    }

    const onClickMenuOpen = (event, subItems) => {
        setMenuDisplay(true)
        setMenuTarget(event.currentTarget)
        setSelectedMenuItems(subItems)
    }

    return (
        <>
            <AppBar position="fixed" style={{ padding: "10px", maxHeight: "85px", minHeight: "85px", boxShadow: "none" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        
                        <Typography
                            variant="h6"
                            noWrap
                            color="primary"
                            sx={{
                                mr: 2,
                                display: { md: 'flex' },
                                fontWeight: 700,
                                color: (props.store.darkMode) ? "#fff" : "#757575",
                                textDecoration: 'none',
                            }}>
                            {config.appName}
                        </Typography>
                        <Typography variant="subtitle2" noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 400,
                                color: (props.store.darkMode) ? "#fff" : "#666666",
                                textDecoration: 'none'
                            }}>
                            | <span style={{ marginLeft: "10px" }}>
                                {config.clientName}
                            </span>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                        </Box>
                       
                        {(props.store.darkMode) ?
                            <Tooltip title="Gündüz Moduna Geç">
                                <IconButton aria-label="delete" color='primary'
                                    className="mps-change-color-mode-button" onClick={onClickDarkLightModeButton}>
                                    <WbSunnyIcon></WbSunnyIcon>
                                </IconButton>
                            </Tooltip> :
                            <Tooltip title="Gece Moduna Geç">
                                <IconButton aria-label="delete"
                                    className="mps-change-color-mode-button" color='primary' onClick={onClickDarkLightModeButton}>
                                    <Brightness3Icon></Brightness3Icon>
                                </IconButton>
                            </Tooltip>
                        }

                        <Tooltip title="Kullanıcı Menüsü" placement='bottom'>
                            <Avatar className='profile-menu' onClick={handleOpenUserMenu} sx={{ p: 0 }}
                                style={{ width: "50px", height: "50px" }}>
                                {props.store.isLogin ? <Typography variant='h6'>
                                    {props.store.user[0]?.name.charAt(0) + props.store.user[0]?.surname.charAt(0)}</Typography>
                                    : <PersonIcon fontSize="inherit" color="primary" />}
                            </Avatar>
                        </Tooltip>

                        <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key={"profilim"} href="/account" component={Link}>
                                <Typography textAlign="center">Profilim</Typography>
                            </MenuItem>
                            <MenuItem key={"logout"} onClick={onClickLogout}>
                                <Typography textAlign="center">Çıkış Yap</Typography>
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar>

            <AppBar position="fixed" style={{ padding: "10px", paddingLeft: "0", maxHeight: "auto", minHeight: "60px", marginTop: "80px" }}>
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                        {pages.map((page) => (
                            (page.isAdmin) ?
                                (props.store.user[0]?.admin === 1 || props.store.user[0]?.role[0]?.id === 50) ?
                                    (page.name === props.page) ?
                                        (page.subPages === undefined) ?
                                            <Button component={Link} href={page.url}
                                                key={page.name} variant="contained"
                                                sx={{ display: 'block', marginRight: "5px", color: "#fff", fontSize: "12px" }}>
                                                {page.icon} {page.name}
                                            </Button>
                                            :
                                            <Button onClick={(event) => onClickMenuOpen(event, page.subPages)}
                                                key={page.name} variant="contained"
                                                sx={{ display: 'block', marginRight: "5px", color: "#fff", fontSize: "12px" }}>
                                                {page.icon} {page.name}
                                            </Button>
                                        :
                                        (page.subPages === undefined) ?
                                            <Button component={Link} href={page.url} key={page.name} color="primary"
                                                sx={{ display: 'block', marginRight: "5px", color: (props.store.darkMode) ? "#fff" : "#a1a5b7", fontSize: "12px" }}>
                                                {page.icon} {page.name}
                                            </Button>
                                            :
                                            <Button onClick={(event) => onClickMenuOpen(event, page.subPages)} key={page.name} color="primary"
                                                sx={{ display: 'block', marginRight: "5px", color: (props.store.darkMode) ? "#fff" : "#a1a5b7", fontSize: "12px" }}>
                                                {page.icon} {page.name}
                                            </Button>
                                    : null
                                :
                                (page.name === props.page) ?
                                    (page.subPages === undefined) ?
                                        <Button component={Link} href={page.url}
                                            key={page.name} variant="contained"
                                            sx={{ display: 'block', marginRight: "5px", color: "#fff", fontSize: "12px" }}>
                                            {page.icon} {page.name}
                                        </Button> :
                                        <Button onClick={(event) => onClickMenuOpen(event, page.subPages)} key={page.name} variant="contained"
                                            sx={{ display: 'block', marginRight: "5px", color: "#fff", fontSize: "12px" }}>
                                            {page.icon} {page.name}
                                        </Button>
                                    :
                                    (page.subPages === undefined) ?
                                        <Button component={Link} href={page.url}
                                            key={page.name}
                                            color="primary"
                                            sx={{ display: 'block', marginRight: "5px", color: (props.store.darkMode) ? "#fff" : "#a1a5b7", fontSize: "12px" }}>
                                            {page.icon} {page.name}
                                        </Button> :
                                        <Button onClick={(event) => onClickMenuOpen(event, page.subPages)} key={page.name} color="primary"
                                            sx={{ display: 'block', marginRight: "5px", color: (props.store.darkMode) ? "#fff" : "#a1a5b7", fontSize: "12px" }}>
                                            {page.icon} {page.name}
                                        </Button>
                        ))}

                    </Box>
                </Container>
                <div style={{ position: "absolute", bottom: "0", height: "5px", width: "100%", zIndex: "9999" }}>
                    <LinearProgress hidden={props.store.loadingBarStatus} color="secondary" />
                </div>

            </AppBar>


            <MapissoMenu
                anchorEl={menuTarget}
                keepMounted
                menuItems={selectedMenuItems}
                open={menuDisplay}
                onClose={onClickMenuClose} style={{ marginTop: "40px" }}
            />

            {(props.breadCrumbsDisplay) ?
                <Container maxWidth="xl" style={{ marginTop: "160px", maxHeight: "40px", minHeight: "40px" }}>
                    <Stack spacing={2}>
                        <Breadcrumbs separator={<FiberManualRecordIcon style={{ fontSize: "7px", color: "#b0b0b0" }} />} aria-label="breadcrumb">
                            {(props.breadCrumbs !== null) ? props.breadCrumbs.map((breadCrumbs, idx) => (
                                <Typography key={idx}>
                                    <Link underline="hover" href={breadCrumbs.url} style={{ fontSize: "12.35px", fontWeight: "500" }}>
                                        {breadCrumbs.title}
                                    </Link>

                                </Typography>
                            )) : null
                            }
                        </Breadcrumbs>
                    </Stack>
                </Container> :
                <Container maxWidth="xl" style={{ marginTop: "160px", maxHeight: "40px", minHeight: "40px" }}>
                </Container>}
            <MapissoAlert />
        </>
    );
}))