import { types } from 'mobx-state-tree'

import { User } from '../model/User'

export const ReazycoStore = types
    .model("ReazycoStore", {
        darkMode: types.boolean,
        isLogin: types.boolean,
        token: types.string,
        messageInfo: types.string,
        messageDisplay: types.boolean,
        user: types.optional(types.array(User), []),
        loadingBarStatus: types.optional(types.boolean, false)
    }).actions(self => ({
        setDarkMode: (function(){
            self.darkMode = !self.darkMode
        }),
        setUserLogged() {
            self.isLogin = true
        },
        setUserLogout() {
            self.isLogin = false
            self.token = ""
        },
        setToken: (function (token) {
            self.token = token
        }),
        setMessage: (function (message, display, messageType) {
            self.messageInfo = message
            self.messageDisplay = display
            self.messageType = (messageType === undefined) ? "info" : messageType
        }),
        setUser: (function (user) {
            self.user = []
            self.user.push(user)
        }),
        setLoadingBarStatus: (function (input) {
            self.loadingBarStatus = input
        })
    }))