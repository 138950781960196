import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Typography } from '@mui/material';

class DashboardStatistics extends Component {

    constructor(props) {
        super(props)
        this.state = {
            statisticsArray: [],
            riskTotalCount: 50,
            tileTotalCount: 1000
        }
    }

    componentDidMount = async () => {

    }

    render() {

        let statistics = this.props.data
        console.log("statistics: ", statistics)

        return (
            <div className="row align-items-start" style={{ marginTop: "0px", marginBottom: "0px" }}>
                <div className="col-12">
                    <Typography variant='h5' style={{ marginBottom: "0px", marginTop: "25px" }}><EqualizerIcon /> Kullanım</Typography>
                    {(statistics !== undefined && statistics.length > 0) ?
                        statistics.map((item, idx) => (

                            (item.year === new Date().getFullYear() && item.month === new Date().getMonth()) ?
                                <Paper key={idx} elevation={0} style={{ padding: "25px" }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                            <Typography variant='body2'>{(item.service === "risk") ? "Risk Servisi" : "Tile Servisi"}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant='body2'>
                                                Kalan
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant='body2'>
                                                Kullanılan
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8} style={{ paddingRight: "15px" }}>
                                            <LinearProgress variant="determinate" value={(item.service === "risk") ? (100 * item.count) / this.state.riskTotalCount : (100 * item.count) / this.state.tileTotalCount}
                                                style={{ marginTop: "5px", height: "10px" }} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <AllInclusiveIcon />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <p>{item.count}</p>
                                        </Grid>

                                    </Grid>
                                </Paper> : ""
                        ))
                        : ""}

                </div>
                <div className="col-2">

                </div>
            </div>

        )
    }
}

DashboardStatistics.propTypes = {
    data: PropTypes.array
}

export default inject("store")(observer(DashboardStatistics));
