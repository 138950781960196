import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class MapissoAlert extends Component {

    constructor(props) {
        super(props)
        this.state = {
            snackBarDisplay: false
        }
    }

    handleClose = (event, reason) => {
        this.props.store.setMessage("", false, this.props.store.messageType)
    }

    render() {

        return (
            <Snackbar open={this.props.store.messageDisplay} autoHideDuration={15000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={this.props.store.messageType} sx={{ width: '100%' }}>
                    {this.props.store.messageInfo}
                </Alert>
            </Snackbar>
        )
    }
}


export default inject("store")(observer(MapissoAlert));