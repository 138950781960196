import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import copy from "copy-to-clipboard";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyAllIcon from '@mui/icons-material/CopyAll';

import { Container, Typography } from '@mui/material';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { ApiKeyAPI } from '../apis/ApiKeyAPI';

export const ApiKeysPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const [apikeyArray, setApikeyArray] = useState([])
    const breadCrumbs = [
        { title: "REAZY.CO", url: "/" },
        { title: "API Anahtarları", url: "/apikeys" }
    ]

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/login")
        } else {
            onGetApiKeyList()
        }
        // eslint-disable-next-line
    }, []);

    const onGetApiKeyList = async () => {
        props.store.setLoadingBarStatus(false)
        let apikeyArrayResult = await ApiKeyAPI.getList(props, false)
        if (apikeyArrayResult.status === 200) {
            setApikeyArray(apikeyArrayResult.result)
        }
        props.store.setLoadingBarStatus(true)
    }

    const onCreateApikeyClick = () => {
        navigate("/new-api-key")
    }

    const onDeleteApikeyClick = async (apikeyId) => {
        props.store.setLoadingBarStatus(false)

        let apikeyDeleteResult = await ApiKeyAPI.delete(props, apikeyId, false)
        
        if (apikeyDeleteResult.status === 200) {
            props.store.setMessage(apikeyDeleteResult.result.message, true, "success")
            await onGetApiKeyList()
        }
        props.store.setLoadingBarStatus(true)
    }


    const onClickCopyApikeyButton = (apikeyInput) => {
        let isCopy = copy(apikeyInput);
        //Dispalying notification
        if (isCopy) {
            props.store.setMessage("Api anahtarı panoya kopyalandı", true, "success")
        }
    }

    return (

        <>
            <ResponsiveAppBar page="API ANAHTARLARI" breadCrumbs={breadCrumbs} breadCrumbsDisplay={true} />

            <Container maxWidth="xl">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{ marginTop: "0px", fontWeight: "bold" }}>API Anahtarları</h2>
                        <p>Reazy.co risk ve tile servislerine erişebilmek için bir API anahtarı oluşturmanız gerekmektedir.</p>
                    </div>
                    <div className="col-6" style={{ textAlign: 'right' }}>
                        <Button variant="contained" color="secondary" onClick={onCreateApikeyClick}
                            style={{ height: "40px", marginTop: '0px' }}>
                            <AddIcon style={{ marginRight: "8px" }} /> Api Anahtarı Oluştur
                        </Button>
                    </div>
                </div>

                <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell ><b>Sıra No</b></TableCell>
                                <TableCell align="left"><b>İSİM</b></TableCell>
                                <TableCell align="left"><b>API KEY</b></TableCell>
                                <TableCell align="left"><b>OLUŞTURULMA TARİHİ</b></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {apikeyArray.map((item, idx) => (
                                <TableRow key={idx}>
                                    <TableCell align="left">
                                        <Typography variant='body1'>
                                            {idx + 1}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography variant='body1'>
                                            {item.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant='body1' style={{ fontWeight: "bold" }}>{item.apikey}
                                            <IconButton color='primary' onClick={() => onClickCopyApikeyButton(item.apikey)}>
                                                <CopyAllIcon />
                                            </IconButton>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant='body1'>
                                            {item.date}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <IconButton variant="contained"
                                            color="secondary"
                                            onClick={() => onDeleteApikeyClick(item.id)}>
                                            <DeleteIcon />
                                        </IconButton>

                                    </TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}))

export default inject("store")(observer(ApiKeysPage));
