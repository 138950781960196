import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import Apidoc from '../components/Apidoc'
import DashboardStatistics from '../components/DashboardStatistics'

import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { Container, Typography } from '@mui/material';
import { StatisticsAPI } from '../apis/StatisticsAPI';

export const ServicesPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const [statisticsData, setStatisticsData] = useState([])
    const  breadCrumbs =  [
        { title: "REAZY.CO", url: "/" }
    ]

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/login")
        } else {
            onGetStatisticsList()
        }
        // eslint-disable-next-line
    }, []);

    const onGetStatisticsList = async () => {
        props.store.setLoadingBarStatus(false)

        let statisticsResult = await StatisticsAPI.get(props, false)

        if (statisticsResult.status === 200) {
            let statisticsResultData = statisticsResult.result

            let statisticsArray = [];

            let riskDataGet = false;
            let tileDataGet = false;
            for (let i = 0; i < statisticsResultData.length; i++) {
                const statisticsTemp = statisticsResultData[i];

                if (statisticsTemp.year === new Date().getFullYear() && statisticsTemp.month === new Date().getMonth()) {
                    if (statisticsTemp.service === "risk" && !riskDataGet) {
                        statisticsArray.push(statisticsTemp)
                        riskDataGet = true
                    }
                    if (statisticsTemp.service === "tile" && !tileDataGet) {
                        statisticsArray.push(statisticsTemp)
                        tileDataGet = true
                    }
                }
            }

            setStatisticsData(statisticsArray)
            if(statisticsArray.length === 0){
                setStatisticsData([
                    {
                        service: "risk",
                        count: 0,
                        year: new Date().getFullYear(),
                        month: new Date().getMonth()
                    },
                    {
                        service: "tile",
                        count: 0,
                        year: new Date().getFullYear(),
                        month: new Date().getMonth()
                    }

                ])
            }
        } else {
            setStatisticsData([
                    {
                        service: "risk",
                        count: 0,
                        year: new Date().getFullYear(),
                        month: new Date().getMonth()
                    },
                    {
                        service: "tile",
                        count: 0,
                        year: new Date().getFullYear(),
                        month: new Date().getMonth()
                    }

                ])
        }
        props.store.setLoadingBarStatus(true)
    }
 
    return (
        <>
            <ResponsiveAppBar page="ANA SAYFA" breadCrumbs={breadCrumbs} breadCrumbsDisplay={true} />
            <Container maxWidth="xl">
                <Typography variant='h3' style={{ marginTop: "0px", fontWeight: "bold" }}>Hoşgeldiniz</Typography>
                <DashboardStatistics data={statisticsData} />
                <Apidoc></Apidoc>
            </Container>
        </>
    )
}))

export default inject("store")(observer(ServicesPage));
