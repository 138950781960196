import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ApiKeyAPI } from '../apis/ApiKeyAPI';
import { StatisticsAPI } from '../apis/StatisticsAPI';
import { BillAPI } from '../apis/BillAPI';

import { Container, Typography } from '@mui/material';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';

export const AccountPage = inject("store")(observer((props) => {

    const navigate = useNavigate();
    const [apikeyArray, setApikeyArray] = useState([])
    const [statistics, setStatistics] = useState([])
    const [bills, setBills] = useState([])

    const breadCrumbs = [
        { title: "REAZY.CO", url: "/" },
        { title: "Profilim", url: "/account" }
    ]

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/login")
        } else {
            onGetApiKeyList();
            onGetStatisticsList();
            getBillList();
        }
        // eslint-disable-next-line
    }, []);

    const onGetApiKeyList = async () => {
        props.store.setLoadingBarStatus(false)
        let apikeyArrayResult = await ApiKeyAPI.getList(props, false)
        if (apikeyArrayResult.status === 200) {
            setApikeyArray(apikeyArrayResult.result)
        }
        props.store.setLoadingBarStatus(true)
    }

    const onGetStatisticsList = async () => {
        props.store.setLoadingBarStatus(false)
        let statisticsResult = await StatisticsAPI.get(props, false)
        if (statisticsResult.status === 200) {
            let statisticsData = statisticsResult.result
            setStatistics(statisticsData)
        }
        props.store.setLoadingBarStatus(true)
    }

    const getBillList = async () => {
        props.store.setLoadingBarStatus(false)
        let billListGetResponse = await BillAPI.getList(props, false)
        if (billListGetResponse.status === 200) {
            let billListData = billListGetResponse.result
            if (billListData.length > 0) {
                setBills(billListData)
            } else {
                setBills([])
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const findMonthName = (monthNumber) => {
        var months = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
        return months[monthNumber];
    }

    let userName = props.store.user[0].name
    let userSurname = props.store.user[0].surname

    return (
        <>
            <ResponsiveAppBar page="PROFİL" breadCrumbs={breadCrumbs} breadCrumbsDisplay={true} />
            <Container maxWidth="xl">

                <div className="row">
                    <div className="col-9">
                        <Paper elevation={1} style={{ padding: "25px", marginTop: "0px" }}>
                            <h3 style={{ fontWeight: "bold" }}>Api Anahtarlarım</h3>
                            <p>Reazy.co risk ve tile servislerine erişebilmek için oluşturulan API anahtarı listesidir.</p>
                            <TableContainer style={{ marginBottom: "50px" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell ><b>Sıra No</b></TableCell>
                                            <TableCell align="left"><b>İsim</b></TableCell>
                                            <TableCell align="left"><b>Token</b></TableCell>
                                            <TableCell align="left"><b>Oluşturulma Tarihi</b></TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apikeyArray.map((item, idx) => (
                                            <TableRow key={item.name}>
                                                <TableCell align="left">{idx + 1}</TableCell>
                                                <TableCell component="th" scope="row">{item.name}</TableCell>
                                                <TableCell align="left">
                                                    <Typography variant='body2'>
                                                        {item.apikey}
                                                    </Typography>

                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant='body2'>
                                                        {item.date}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="col-3">
                        <h4 style={{ marginTop: "50px", fontWeight: "bold" }}>Hesabım</h4>
                        <p>{userName} {userSurname}</p>
                        <h4 style={{ marginTop: "20px", fontWeight: "bold" }}>Planım</h4>
                        <p>{(props.store.user[0].paid) ? "Ücretli Plan" : "Ücretsiz Plan"}</p>
                        <h4 style={{ marginTop: "20px", fontWeight: "bold" }}>Mevcut Kullanım Durumu</h4>

                        {(statistics !== undefined && statistics.length > 0) ?
                            statistics.map((item, idx) => (
                                (item.year === new Date().getFullYear() && item.month === new Date().getMonth()) ?
                                    <div>
                                        <p style={{ marginBottom: "0", fontWeight: "bold" }}>{(item.service === "risk") ? "Risk Servisi" : "Tile Servisi"}</p>
                                        <span>{(item.service === "risk") ? item.count : item.count}</span>
                                        <LinearProgress variant="determinate" value={100}
                                            style={{ marginTop: "5px", height: "10px" }} />
                                    </div> : ""
                            )) : ""
                        }

                    </div>

                </div>
                <div className="row" style={{ marginBottom: "100px" }}>
                    <div className="col-9">
                        <Paper elevation={1} style={{ padding: "25px", marginTop: "50px" }}>
                            <h3 style={{ fontWeight: "bold" }}>Faturalarım</h3>
                            <p>Reazy.co risk ve tile servis kullanım bilgileri</p>
                            <TableContainer style={{ marginBottom: "50px" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Sıra No</b></TableCell>
                                            <TableCell ><b>Fatura Yıl</b></TableCell>
                                            <TableCell align="left"><b>Fatura Ay</b></TableCell>
                                            <TableCell align="left"><b>Risk Servisi Kullanımı</b></TableCell>
                                            <TableCell align="left"><b>Tile Servisi Kullanımı</b></TableCell>
                                            <TableCell align="left"><b>Bedel</b></TableCell>
                                            <TableCell align="left"><b>Tarih</b></TableCell>
                                            <TableCell align="left"><b>Ödeme Durumu</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bills.map((item, idx) => (
                                            <TableRow key={item.id}>
                                                <TableCell align="left">{idx + 1}</TableCell>
                                                <TableCell component="th" scope="row">{item.year}</TableCell>
                                                <TableCell align="left">{findMonthName(item.month)}</TableCell>
                                                <TableCell align="left">{item.risk}</TableCell>
                                                <TableCell align="left">{item.tile}</TableCell>
                                                <TableCell align="left">{item.price} TRY</TableCell>
                                                <TableCell align="left">{item.date}</TableCell>
                                                <TableCell align="left">{(item.status) ? "Ödendi" : "Ödenmedi"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>
                    </div>
                    <div className="col-3">

                    </div>

                </div>
            </Container>
        </>
    )
}))

export default inject("store")(observer(AccountPage));
