import React from "react";
import { observer, inject } from "mobx-react"

import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from '@mui/material/MenuList';
import Link from '@mui/material/Link';

import { MapissoSubMenu } from "./MapissoSubMenu";

export const MapissoMenu = inject("store")(observer((props) => {

    const renderMenuItems = () => {
        const { menuItems } = props;
        return menuItems.map(menuItem => {
            if (menuItem.hasOwnProperty("subPages")) {
                return (
                    <MapissoSubMenu anchorEl={menuItem.anchorEl}
                        key={menuItem.key}
                        caption={menuItem.name}
                        menuItems={menuItem.subPages}
                    />
                );
            }
            return (
                <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
                    <Link href={menuItem.url} color="inherit" style={{ width: "100%", textDecoration: "none" }}>
                        {menuItem.name}
                    </Link>
                </MenuItem>
            );
        });
    };

    return (
        <MuiMenu anchorEl={props.anchorEl} open={props.open} onClose={props.onClose}>
            <MenuList>
                {renderMenuItems()}
            </MenuList>
        </MuiMenu>
    )

}))