import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from "mobx-react";
import { onSnapshot } from 'mobx-state-tree'
import App from './App';

import { ReazycoStore } from './store/ReazycoStore'

let initialState = {
  darkMode: false,
  isLogin: false,
  token: "",
  messageInfo: "",
  messageDisplay: false,
}

if (localStorage.getItem("reazyco.panel.1.0.0")) {
  // console.log("localstorage if")
  // localStorage.clear("reazyco.panel.1.0.0")
  initialState = JSON.parse(localStorage.getItem("reazyco.panel.1.0.0"))
}

let reazycoStore = ReazycoStore.create(initialState)

onSnapshot(reazycoStore, snapshot => {
  localStorage.setItem("reazyco.panel.1.0.0", JSON.stringify(snapshot))
})

function renderApp() {
  let root = ReactDOM.createRoot(document.getElementById("root"))

  root.render(
    <Provider store={reazycoStore}>
      <App />
    </Provider>); 
}

renderApp()