import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';

import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2'

import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { Container } from '@mui/material';
import { StatisticsAPI } from '../apis/StatisticsAPI';

Chart.register(...registerables);

const options = {
    legend: {
        display: false
    },
    scales: {
        y: {
            ticks: {
                autoSkip: true,
                fontColor: 'rgb(35, 8, 87)',
                fontStyle: "bold",
                maxRotation: 0,
                minRotation: 0,
                padding: 15,
                labelOffset: 15,
                autoSkipPadding: 50
            },
            gridLines: {
                display: true
            },
            stacked: true
        },
        x: {
            gridLines: {
                display: false
            },
        }
    },
}

export const StatisticsPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const labelData = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
    
    const [tileData, setTileData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Tile İstekleri',
                data: [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            }
        ]
    })

    const [riskData, setRiskData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Tile İstekleri',
                data: [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            }
        ]
    });

    const  breadCrumbs =  [
        { title: "REAZY.CO", url: "/" },
        { title: "İstatistikler", url: "/statistics" }
    ]

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/login")
        } else {
            onGetStatisticsList()
        }
        // eslint-disable-next-line
    }, []);

    const onGetStatisticsList = async () => {

        props.store.setLoadingBarStatus(false)
        let statisticsResult = await StatisticsAPI.get(props, false)

        if (statisticsResult.status === 200) {

            let statisticsData = statisticsResult.result
            let riskData = []
            let tileData = []

            let year = new Date().getFullYear();

            let riskDataArray = []
            let tileDataArray = []

            for (let i = 0; i < labelData.length; i++) {
                let statisticsRiskTemp = {
                    month: i,
                    year: year,
                    count: 0,
                    type: "risk"
                }

                let statiticsTileTemp = {
                    month: i,
                    year: year,
                    count: 0,
                    type: "tile"
                }

                riskDataArray.push(statisticsRiskTemp)
                tileDataArray.push(statiticsTileTemp)

            }

            statisticsData.forEach(function (item, index) {
                if (item.service === 'risk') {
                    //console.log("item.count: ", item.count)
                    riskDataArray[item.month].count = item.count
                    riskData.push(item)
                } else {
                    //console.log("---item.count: ", item.count)
                    tileDataArray[item.month].count = item.count
                    tileData.push(item)
                }
            });

            //console.log("tileDataArray: ", tileDataArray)
            //console.log("riskDataArray: ", riskDataArray)

            let riskGraphData = []
            if (riskDataArray.length > 0) {
                riskDataArray.forEach(function (item, index) {
                    riskGraphData.push(item.count)
                });
            }

            let tileGraphData = []
            if (tileDataArray.length > 0) {
                tileDataArray.forEach(function (item, index) {
                    tileGraphData.push(item.count)
                })
            }

            const tileGraph = {
                labels: labelData,
                datasets: [
                    {
                        label: 'Tile İstekleri',
                        data: tileGraphData,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    }
                ]
            }

            const riskGraph = {
                labels: labelData,
                datasets: [
                    {
                        label: 'Risk Servis İstekleri',
                        data: riskGraphData,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    }
                ]
            }

            //setStatisticsData(statisticsArray);
            setTileData(tileGraph)
            setRiskData(riskGraph)

        } 

        props.store.setLoadingBarStatus(true)

    }

    return (
        <>
            <ResponsiveAppBar page="İSTATİSTİK" breadCrumbs={breadCrumbs} breadCrumbsDisplay={true} />

            <Container maxWidth="xl">
                <h2 style={{ marginTop: "0px", fontWeight: "bold" }}>İstatistikler</h2>
               
                <div className="row">
                    <Paper elevation={0} style={{ padding: "25px" }}>
                        <h3 style={{ marginBottom: '50px' }}><b>APIs</b></h3>
                        <div className="row">
                            <div className="col-3">
                                <p style={{ fontWeight: 'bold', marginBottom: "0" }}>Risk API</p>
                                <span>Aylık Service İstekleri</span>
                            </div>
                            <div className="col-9">
                                <Line data={riskData} options={options} height={150} width={650} />
                            </div>
                        </div>
                    </Paper>

                </div>
                <div className="row" style={{ marginBottom: '150px' }}>
                    <Paper elevation={0} style={{ padding: "25px" }}>

                        <div className="row">
                            <div className="col-3">
                                <p style={{ fontWeight: 'bold', marginBottom: "0" }}>Tile API</p>
                                <span>Aylık Service İstekleri</span>
                            </div>
                            <div className="col-9">
                                <Line data={tileData} options={options} height={150} width={650} />
                            </div>
                        </div>
                    </Paper>

                </div>

            </Container>
        </>
    )
}))

export default inject("store")(observer(StatisticsPage));