import { api } from "./configs/AxiosConfigs"
import { defineCancelApiObject } from "./configs/AxiosUtils"

import config from '../config/client.json'

export const BillAPI = {
    getList: async function (props, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `${config.serviceUrl}/bill`,
            method: "GET",
            headers: { 'Authorization': "Bearer " + props.store.token, 'Content-Type': "application/json" },
            data: null,
            signal: cancel ? cancelApiObject[this.getList.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    }
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(BillAPI) 