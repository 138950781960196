import { types } from "mobx-state-tree"

export const User = types
    .model({
        id: types.optional(types.string, ""),
        name: types.optional(types.string,""),
        surname: types.optional(types.string, ""),
        email: types.optional(types.string, ""),
        password: types.optional(types.string, ""),
        token: types.optional(types.string, ""),
        confirmed: types.optional(types.boolean, false),
        paid: types.optional(types.boolean, false),
        companyName: types.optional(types.string, ""),
        companyAddress: types.optional(types.string, ""),
        companyEmail: types.optional(types.string, ""),
        companyTaxNumber: types.optional(types.string, ""),
        companyTaxAdministration: types.optional(types.string, ""),
    }).actions(self => ({

    }))

 